<template>
  <div>
    <clinicList
      :canEdit="true"
      editRoute="SuperAdminClinicEdit"
      :canCreate="true"
      createRoute="SuperAdminClinicCreate"
      :canViewDetail="true"
      viewRoute="SuperAdminClinicDetail"
      :canViewClinicTreatmentHistory="true"
      clinicTreatmentHistoryRoute="SuperAdminClinicTreatmentHistory"
    />
  </div>
</template>

<script>
import clinicList from "../../components/clinics/clinicList.vue";

export default {
  components: {
    clinicList
  },
  data: () => ({})
};
</script>
